<template>
  <v-tabs v-model="selectedTab" grow class="elevate-tab-headers">
    <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
      {{ tab.text }}
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>
    <v-tabs-items
      v-model="selectedTab"
      touchless
      class="elevation-0 transparent"
    >
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <moderator-promotion-tab
          v-if="tab.name === 'promoted'"
        ></moderator-promotion-tab>
        <current-day-tab
          v-else-if="tab.name === 'current_day'"
        ></current-day-tab>
        <current-month-tab
          v-else-if="tab.name === 'current_month'"
        ></current-month-tab>
        <monthly-tab v-else-if="tab.name === 'monthly'"></monthly-tab>
        <yearly-tab v-else-if="tab.name === 'yearly'"></yearly-tab>
        <v-alert v-else :value="true" type="warning"
          >Under construction</v-alert
        >
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import CurrentDayTab from "../../common/dashboard/CurrentDayTab.vue";
import CurrentMonthTab from "../../common/dashboard/CurrentMonthTab.vue";
import MonthlyTab from "../../common/dashboard/MonthlyTab.vue";
import YearlyTab from "../../common/dashboard/YearlyTab.vue";
import ModeratorPromotionTab from "../../common/dashboard/ModeratorPromotionTab.vue";

export default {
  components: {
    YearlyTab,
    ModeratorPromotionTab,
    MonthlyTab,
    CurrentMonthTab,
    CurrentDayTab
  },
  props: {
    // props
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      { name: "promoted", text: "Fremhævet" },
      { name: "current_day", text: "I dag" },
      { name: "current_month", text: "Denne måned" },
      { name: "monthly", text: "Månedlig" },
      { name: "yearly", text: "Årlig" }
    ]
  })
};
</script>
