<template>
  <v-chart :options="options" :auto-resize="true"></v-chart>
</template>

<script>
export default {
  props: {
    value: {
      default: () => {}
    },
    legend: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    options() {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%) brugere"
        },
        legend: {
          data: this.legend
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            data: this.chartData,
            label: {
              normal: {
                formatter: "{d}%",
                position: "inner"
              }
            },
            labelLine: true,
            animationEasing: "elasticOut",
            animationDelay: () => {
              return Math.random() * 200;
            }
          }
        ]
      };
    },
    chartData() {
      return [
        {
          name: "Not active",
          value: this.value.total - this.value.active,
          itemStyle: {
            color: this.$theme.primary
          }
        },
        {
          name: "Active",
          value: this.value.active,
          itemStyle: {
            color: this.$theme.accent
          }
        }
      ];
    }
  }
};
</script>
