<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      hide-default-footer
      :items-per-page="10000"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.uuid">
            <td>
              <router-link
                :to="{ name: 'influencer', params: { id: item.influencer.uuid } }"
              >
                {{
                  item.influencer.user.first_name +
                    " " +
                    item.influencer.user.last_name
                }}
              </router-link>
            </td>
            <td class="text-right">{{ item.clicks | local_numbers }}</td>
            <td class="text-right">{{ item.sales | local_numbers }}</td>
            <td class="text-right">
              {{ item.campaigns.length | local_numbers }}
            </td>
            <td class="text-right">
              <v-btn
                slot="activator"
                icon
                outlined
                color="primary"
                @click="selectedItem = item"
              >
                <v-icon small>fal fa-bullhorn</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog v-if="selectedItem !== null" v-model="showDialog" max-width="85%">
      <v-card>
        <v-card-title>
          <div>
            <div class="headline">Stats fordelt per kampagne</div>
            <div class="subtitle-1 light-grey">
              {{
                selectedItem.influencer.user.first_name +
                  " " +
                  selectedItem.influencer.user.last_name
              }}
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <campaign-stats-data-table
            :items="selectedItem.campaigns"
          ></campaign-stats-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import CampaignStatsDataTable from "../../data-tables/CampaignStatsDataTable";

export default {
  components: { CampaignStatsDataTable },
  filters: {
    local_numbers
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  data: () => ({
    headers: [
      { text: "Influencer", value: "user.first_name" },
      { text: "Clicks", value: "clicks", align: "right" },
      { text: "Sales", value: "sales", align: "right" },
      { text: "Campaigns", value: "campaigns", align: "right" },
      { text: "", sortable: false }
    ],
    selectedItem: null
  }),
  computed: {
    filteredItems() {
      let stats = [];

      this.items.forEach(item => {
        let influencerIndex = stats.findIndex(
          tmpItem => tmpItem.influencer.uuid === item.influencer.uuid
        );

        // If influencer exists else push
        if (influencerIndex > -1) {
          // Add total data
          stats[influencerIndex].clicks += parseInt(item.clicks);
          stats[influencerIndex].sales += parseInt(item.sales);
          stats[influencerIndex].campaigns.push(item);
        } else {
          // new influencer
          let influencer = {
            influencer: item.influencer,
            clicks: parseInt(item.clicks),
            sales: parseInt(item.sales),
            campaigns: []
          };

          influencer.campaigns.push(item);

          stats.push(influencer);
        }
      });

      return stats;
    },
    showDialog: {
      get() {
        return this.selectedItem !== null;
      },
      set() {
        this.selectedItem = null;
      }
    }
  }
};
</script>
