<template>
  <div>
    <div class="headline">Velkommen {{ user.first_name }}</div>
    <div class="subtitle-1 light-grey">Oversigt over nuværende måned</div>
    <br />
    <moderator-dashboard-tabs></moderator-dashboard-tabs>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import { mapState } from "vuex";
import ModeratorDashboardTabs from "../dashboard/ModeratorDashboardTabs.vue";

export default {
  components: {
    ModeratorDashboardTabs
  },
  filters: { local_numbers },
  data: () => ({
    stats: {},
    sales: [],
    dailyStats: [],
    monthlyStats: [],
    topInfluencers: [],
    topBusinesses: [],
    activeUsers: {}
  }),
  computed: {
    ...mapState("core/auth", ["user"])
  },
  created() {
    //  created
  }
};
</script>
