<template>
  <div>
    <v-container fluid grid-list-xl class="pl-0 pr-0" v-if="!isLoading">
      <v-layout wrap>
        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-hand-point-up"
            title="Clicks"
            :total="total.clicks"
            :predicted="predicted.clicks"
            :current="formattedHourlyData.clicks"
            :previous="formattedPreviousHourlyData.clicks"
          ></stat-widget>
        </v-flex>
        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-fingerprint"
            title="Unique clicks"
            :total="total.unique_clicks"
            :predicted="predicted.unique_clicks"
            :current="formattedHourlyData.unique_clicks"
            :previous="formattedPreviousHourlyData.unique_clicks"
            :cpc="total.cpc"
          ></stat-widget>
        </v-flex>
        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-shopping-basket"
            title="Sales"
            :total="total.sales"
            :predicted="predicted.sales"
            :conversion="total.conversion + '%'"
            :current="formattedHourlyData.sales"
            :previous="formattedPreviousHourlyData.sales"
          ></stat-widget>
        </v-flex>
        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-money-bill-wave"
            title="Commission"
            :total="total.commission | local_numbers"
            :predicted="predicted.commission | local_numbers"
            :current="formattedHourlyData.commission"
            :previous="formattedPreviousHourlyData.commission"
          ></stat-widget>
        </v-flex>
        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-coins"
            title="Revenue"
            :total="total.revenue | local_numbers"
            :predicted="predicted.revenue | local_numbers"
            :current="formattedHourlyData.revenue"
            :previous="formattedPreviousHourlyData.revenue"
            :roi="total.roi"
          ></stat-widget>
        </v-flex>
        <v-flex v-if="topInfluencers.length > 0" xs12 sm6>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Top influencers</div>
                <!--<span class="grey&#45&#45text">Influencers der har lavet flest salg denne måned</span>-->
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="[
                  { text: 'Influencer', value: 'influencer.user.first_name' },
                  { text: 'Clicks', value: 'clicks' },
                  { text: 'Sales', value: 'sales' }
                ]"
                :items="topInfluencers"
                :items-per-page="10000"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.influencer.uuid">
                      <td>
                        <router-link
                          :to="{
                            name: 'influencer',
                            params: { id: item.influencer.uuid }
                          }"
                        >
                          {{ item.influencer.user.first_name }}
                          {{ item.influencer.user.last_name }}
                        </router-link>
                      </td>
                      <td>{{ item.clicks }}</td>
                      <td>{{ item.sales }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 v-if="isModerator && topBusinesses.length > 0">
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Top brands</div>
                <!--<span class="grey&#45&#45text">Virksomheder der har lavet flest salg denne måned</span>-->
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="[
                  { text: 'Brand', value: 'business.display_name' },
                  { text: 'Clicks', value: 'clicks' },
                  { text: 'Sales', value: 'sales' }
                ]"
                :items="topBusinesses"
                :items-per-page="10000"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.business.uuid">
                      <td>
                        <router-link
                          :to="{
                            name: 'business',
                            params: { id: item.business.uuid }
                          }"
                        >
                          {{ item.business.display_name }}
                        </router-link>
                      </td>
                      <td>{{ item.clicks }}</td>
                      <td>{{ item.sales }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-flex class="d-flex flex-wrap pt-2" v-else>
      <v-flex lg4 md6 xs12 v-for="(item, k) in 6" :key="`a-${k}`">
        <v-card class="p-2 m-2">
          <v-skeleton-loader
            type="list-item-avatar"
            class="mb-3"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-card>
      </v-flex>
      <v-flex lg6 md6 xs12 v-for="(item, k) in 2" :key="`b-${k}`">
        <v-card class="p-2 m-2">
          <v-skeleton-loader type="heading" class="mb-3"></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded"
            width="100%"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-card>
      </v-flex>
    </v-flex>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import StatWidget from "../../cards/widgets/StatWidget";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { StatWidget },
  filters: { local_numbers },
  props: {},
  data: () => ({
    hourlyStats: [],
    previousHourlyStats: [],
    topInfluencers: [],
    topBusinesses: [],
    isLoading: true
  }),
  computed: {
    ...mapGetters("core/auth", ["isModerator", "isBusiness"]),
    formattedHourlyData() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };
      let now = this.$moment().utc();
      // Loop for 24 hours and check if any data
      for (let i = 0; i < 24; i++) {
        let dateTime = this.$moment()
          .utc()
          .set({ hour: i, minute: 0, second: 0, millisecond: 0 });
        // If hour hasn't passed skip.
        if (i > parseInt(now.format("HH"))) {
          continue;
        }
        let hasHour = this.hourlyStats.filter(
          item => parseInt(item.hour) === i
        );
        // No data for this hour
        if (hasHour.length === 0) {
          final.clicks.push([dateTime.format(), 0]);
          final.unique_clicks.push([dateTime.format(), 0]);
          final.sales.push([dateTime.format(), 0]);
          final.revenue.push([dateTime.format(), 0]);
          final.commission.push([dateTime.format(), 0]);
        } else {
          final.clicks.push([dateTime.format(), hasHour[0].clicks]);
          final.unique_clicks.push([
            dateTime.format(),
            hasHour[0].unique_clicks
          ]);
          final.sales.push([dateTime.format(), hasHour[0].sales]);
          final.revenue.push([dateTime.format(), hasHour[0].value]);
          final.commission.push([dateTime.format(), hasHour[0].commission]);
        }
      }
      return final;
    },
    formattedPreviousHourlyData() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };
      let now = this.$moment().utc();
      // Loop for 24 hours and check if any data
      for (let i = 0; i < 24; i++) {
        const dateTimeThisYear = this.$moment()
          .subtract(1, "day")
          .utc()
          .set({ hour: i, minute: 0, second: 0, millisecond: 0 });
        const dateTimeLastYear = this.$moment()
          .subtract(1, "year")
          .utc()
          .set({ hour: i, minute: 0, second: 0, millisecond: 0 });
        const dateTime = this.isModerator ? dateTimeLastYear : dateTimeThisYear
        // If hour hasn't passed skip.
        if (i > parseInt(now.format("HH"))) {
          continue;
        }
        let hasHour = this.previousHourlyStats.filter(
          item => parseInt(item.hour) === i
        );
        // No data for this hour
        if (hasHour.length === 0) {
          final.clicks.push([dateTime.format(), 0]);
          final.unique_clicks.push([dateTime.format(), 0]);
          final.sales.push([dateTime.format(), 0]);
          final.revenue.push([dateTime.format(), 0]);
          final.commission.push([dateTime.format(), 0]);
        } else {
          final.clicks.push([dateTime.format(), hasHour[0].clicks]);
          final.unique_clicks.push([
            dateTime.format(),
            hasHour[0].unique_clicks
          ]);
          final.sales.push([dateTime.format(), hasHour[0].sales]);
          final.revenue.push([dateTime.format(), hasHour[0].value]);
          final.commission.push([dateTime.format(), hasHour[0].commission]);
        }
      }
      return final;
    },
    total() {
      let final = {
        clicks: 0,
        unique_clicks: 0,
        sales: 0,
        revenue: 0,
        commission: 0,
        conversion: 0,
        roi: 0,
        cpc: 0
      };
      this.hourlyStats.forEach(function(item) {
        final.clicks += parseInt(item.clicks);
        final.unique_clicks += parseInt(item.unique_clicks);
        final.sales += parseInt(item.sales);
        final.revenue += parseFloat(item.value);
        final.commission += parseFloat(item.commission);
      });
      final.revenue = Math.round(final.revenue * 100) / 100;
      final.commission = Math.round(final.commission * 100) / 100;
      if (final.sales && final.clicks) {
        final.conversion =
          Math.round((final.sales / final.unique_clicks) * 100 * 100) / 100;
      }
      if (final.commission && final.unique_clicks) {
        final.roi =
          (Number(final.revenue) - Number(final.commission)) /
          Number(final.commission);
        final.cpc = Number(final.commission) / Number(final.unique_clicks);
      }
      return final;
    },
    predicted() {
      let hour = this.$moment()
        .utc()
        .format("HH");
      hour = parseInt(hour); // Convert to int so we can calculate
      let avgClickPerHour = this.total.clicks / hour;
      let avgUniqueClicksPerHour = this.total.unique_clicks / hour;
      let avgSalesPerHour = this.total.sales / hour;
      let avgRevenuePerHour = this.total.revenue / hour;
      let avgCommissionPerHour = this.total.commission / hour;
      return {
        clicks: Math.round(24 * avgClickPerHour),
        unique_clicks: Math.round(24 * avgUniqueClicksPerHour),
        sales: Math.round(24 * avgSalesPerHour),
        revenue: Math.round(24 * avgRevenuePerHour),
        commission: Math.round(24 * avgCommissionPerHour)
      };
    }
  },
  methods: {
    ...mapActions("core", [
      "getStats",
      "getDashboardBusinessStats",
      "getDashboardInfluencerStats"
    ]),
    getHourlyStats() {
      let start = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      let end = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      let startFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD 00:00:00");
      let endFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD 23:59:59");
      let params = {
        start_date: start,
        end_date: end,
        starting_at: startFull,
        ending_at: endFull,
        group: ["hour"]
      };
      this.getStats(params).then(
        stats => {
          this.hourlyStats = stats;
        },
        error => {
          console.log(error);
        }
      );
      start = this.$moment()
        .utc()
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      end = this.$moment()
        .utc()
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      startFull = this.$moment()
        .utc()
        .subtract(1, "day")
        .format("YYYY-MM-DD 00:00:00");
      endFull = this.$moment()
        .utc()
        .subtract(1, "day")
        .format("YYYY-MM-DD 23:59:59");
      const lastYearStart = this.$moment()
        .utc()
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      const lastYearEnd = this.$moment()
        .utc()
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      const lastYearStartFull = this.$moment()
        .utc()
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:00");
      const lastYearEndFull = this.$moment()
        .utc()
        .subtract(1, "year")
        .format("YYYY-MM-DD 23:59:59");
      params = {
        start_date: this.isModerator ? lastYearStart : start,
        end_date: this.isModerator ? lastYearEnd : end,
        starting_at: this.isModerator ? lastYearStartFull : startFull,
        ending_at: this.isModerator ? lastYearEndFull : endFull,
        group: ["hour"]
      };
      this.getStats(params).then(
        stats => {
          this.previousHourlyStats = stats;
          this.isLoading = false;
        },
        error => {
          console.log(error);
        }
      );
    },
    getTopInfluencers() {
      const start = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      const end = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      const startFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD 00:00:00");
      const endFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD 23:59:59");
      let params = {
        start_date: start,
        end_date: end,
        starting_at: startFull,
        ending_at: endFull,
        group: ["influencer"],
        order: ["conversions", "clicks"],
        limit: 10
      };
      this.getDashboardInfluencerStats(params).then(
        response => {
          this.topInfluencers = response.data;
        },
        error => {
          console.log(error);
        }
      );
    },
    getTopBusinesses() {
      const start = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      const end = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      const startFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD 00:00:00");
      const endFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD 23:59:59");
      let params = {
        start_date: start,
        end_date: end,
        starting_at: startFull,
        ending_at: endFull,
        group: ["business"],
        order: ["conversions"],
        limit: 10
      };
      this.getDashboardBusinessStats(params).then(
        response => {
          this.topBusinesses = response.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {
    // watchers
  },
  created() {
    // tempony fix for dashboard
    this.getHourlyStats();
    this.getTopInfluencers();
    if (this.isModerator) {
      this.getTopBusinesses();
    }
  }
};
</script>
