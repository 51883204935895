<template>
  <div>
    <v-container fluid grid-list-xl class="pl-0 pr-0">
      <v-layout v-if="!isLoading" wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Clicks</div>
              </div>
            </v-card-title>
            <v-card-text>
              <yearly-chart v-model="formattedStats.clicks"></yearly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Unique clicks</div>
              </div>
            </v-card-title>
            <v-card-text>
              <yearly-chart
                v-model="formattedStats.unique_clicks"
              ></yearly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Sales</div>
              </div>
            </v-card-title>
            <v-card-text>
              <yearly-chart v-model="formattedStats.sales"></yearly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Commission</div>
              </div>
            </v-card-title>
            <v-card-text>
              <yearly-chart v-model="formattedStats.commission"></yearly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Revenue</div>
              </div>
            </v-card-title>
            <v-card-text>
              <yearly-chart v-model="formattedStats.revenue"></yearly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-flex class="pt-2" v-else>
        <v-flex xs12 v-for="item in 6" :key="item" class="mb-3">
          <v-card class="p-2 w-100">
            <v-skeleton-loader
              type="card-heading"
              class="mb-2"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="rounded"
              width="100%"
              type="image"
            ></v-skeleton-loader>
          </v-card>
        </v-flex>
      </v-flex>
    </v-container>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import YearlyChart from "../../charts/YearlyChart";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { YearlyChart },
  filters: { local_numbers },
  data: () => ({
    isLoading: true,
    stats: []
  }),
  computed: {
    ...mapGetters("core/auth", [
      "isModerator",
      "isBusiness",
      "isInfluencer",
      "isAgency"
    ]),
    formattedStats() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };

      let stats = this.cloneDeep(this.stats).reverse();
      stats.forEach(item => {
        final.clicks.push([item.date, item.clicks]);
        final.unique_clicks.push([item.date, item.unique_clicks]);
        final.sales.push([item.date, item.sales]);
        final.revenue.push([item.date, item.value]);
        final.commission.push([item.date, item.commission]);
      });
      return final;
    }
  },
  methods: {
    ...mapActions("core", ["getStats"]),
    getYerlyStats() {
      let params = {
        start_date: "2018-12-01",
        end_date: this.$moment()
          .utc()
          .format("YYYY-MM-DD"),
        starting_at: "2018-12-01 00:00:00",
        ending_at: this.$moment()
          .utc()
          .format("YYYY-MM-DD hh:mm:ss"),
        group: ["year"]
      };
      this.getStats(params).then(
        stats => {
          this.stats = stats;
          this.isLoading = false;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getYerlyStats();
  }
};
</script>
