<template>
  <div>
    <v-container fluid grid-list-xl class="pl-0 pr-0">
      <v-layout wrap>
        <v-flex xs12 sm6 md3>
          <v-card class="flex-card" style="height: 100%">
            <v-card-title class="pb-0">
              <div>
                <div class="headline">Performance today</div>
              </div>
            </v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-hand-point-up</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayStats.clicks | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Clicks</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-fingerprint</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayStats.unique_clicks | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Unique clicks</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-shopping-basket</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayStats.sales | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Sales</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-percent</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayStats.conversion | local_numbers }}%
                  </v-list-item-title>
                  <v-list-item-title>Conversions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-money-bill-wave</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentDayStats.commission | local_numbers }}
                  </v-list-item-title>
                  <v-list-item-title>Commission</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md9>
          <v-card class="flex-card" style="height: 100%">
            <v-card-title class="pb-0">
              <div>
                <div class="headline">Performance in the past week</div>
              </div>
            </v-card-title>
            <v-card-text>
              <daily-stats-data-table
                :items="pastWeekStats"
              ></daily-stats-data-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm6 xs12>
          <v-card class="flex-card" style="height: 100%">
            <v-card-title>
              <div>
                <div class="headline">Active influencers</div>
                <span class="subtitle-1 light-grey">
                  Earned commission last 30 days
                </span>
              </div>
            </v-card-title>
            <v-card-text class="d-flex align-center">
              <active-users-chart
                v-if="activeUsers.influencer"
                :value="activeUsers.influencer"
                :legend="['Active', 'Not active']"
                style="max-height: 300px"
              ></active-users-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm6 xs12>
          <v-card class="flex-card" style="height: 100%">
            <v-card-title>
              <div>
                <div class="headline">Active businesses</div>
                <span class="subtitle-1 light-grey">
                  Minimum 150 clicks last 30 days
                </span>
              </div>
            </v-card-title>
            <v-card-text class="d-flex align-center">
              <active-users-chart
                v-if="activeUsers.business"
                :value="activeUsers.business"
                :legend="['Active', 'Not active']"
                style="max-height: 300px"
              ></active-users-chart>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm6 xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Company signups</div>
                <span class="subtitle-1 light-grey"
                  >Last 5 company signups on the platform</span
                >
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="[
                  { text: 'Signed up', sortable: false },
                  { text: 'Company', sortable: false }
                ]"
                :items="latestBusinesses"
                :items-per-page="10000"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.uuid">
                      <td>{{ item.created_at | utc_to_local_datetime }}</td>
                      <td>
                        <router-link
                          :to="{ name: 'business', params: { id: item.uuid } }"
                        >
                          {{ item.display_name }}
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm6 xs12>
          <v-card class="flex-card" style="height: 100%">
            <v-card-title>
              <div>
                <div class="headline">Commission status</div>
                <span class="subtitle-1 light-grey"
                  >Commission distribution</span
                >
              </div>
            </v-card-title>
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-certificate</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    commissionStatus.new | local_numbers
                  }}</v-list-item-title>
                  <v-list-item-subtitle>New sales</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-badge-check</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    commissionStatus.approved | local_numbers
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Approved (invoiced Monday)</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-receipt</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    commissionStatus.invoiced | local_numbers
                  }}</v-list-item-title>
                  <v-list-item-subtitle>Invoiced</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-hourglass</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    commissionStatus.pending_payout | local_numbers
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Awaiting payment to influencer account
                    </v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>

        <v-flex v-if="openInvoices.length > 0" xs12 sm6>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Unpaid stripe invoice</div>
                <span class="subtitle-1 light-grey"
                  >{{ openInvoices.length }} unpaid invoice</span
                >
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="[
                  { text: 'Date', value: 'date' },
                  { text: 'Kunde', value: 'business.user.first_name' },
                  { text: 'Beløb', value: 'value', align: 'right' },
                  { text: '', sortable: false }
                ]"
                :items="openInvoices"
                hide-default-footer
                :items-per-page="10000"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, key) in items" :key="key">
                      <td class="word-no-wrap">
                        {{ item.date | utc_to_local_datetime }}
                      </td>
                      <td>
                        <router-link
                          v-if="item.business"
                          :to="{
                            name: 'business',
                            params: { id: item.business.uuid }
                          }"
                        >
                          {{ item.business.display_name }}
                        </router-link>
                      </td>
                      <td class="text-right">
                        {{ item.total | local_numbers }}
                      </td>
                      <td class="text-right word-no-wrap">
                        <v-btn
                          icon
                          outlined
                          color="primary"
                          class="mr-0"
                          title="See invoice"
                          :href="item.view_url"
                          target="_blank"
                        >
                          <v-icon small>fal fa-eye</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          outlined
                          color="primary"
                          title="Download invoice"
                          :href="item.download_url"
                          target="_blank"
                        >
                          <v-icon small>fal fa-file-download</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <v-card class="flex-card" style="height: 100%">
            <v-card-title>
              <div>
                <div class="headline">Traffic today</div>
                <span class="subtitle-1 light-grey">Influencer traffic</span>
              </div>
            </v-card-title>
            <v-card-text>
              <influencer-traffic-data-table
                :items="influencerCampaignStats"
              ></influencer-traffic-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import local_numbers from "@/helpers/filters/local_numbers";
import ActiveUsersChart from "../../moderator/charts/ActiveUsersChart";
import InfluencerTrafficDataTable from "../../moderator/data-tables/InfluencerTrafficDataTable";
import DailyStatsDataTable from "../../data-tables/DailyStatsDataTable";
import { mapActions } from "vuex";

export default {
  components: {
    DailyStatsDataTable,
    InfluencerTrafficDataTable,
    ActiveUsersChart
  },
  filters: { utc_to_local_datetime, local_numbers },
  data: () => ({
    currentDayStats: {},
    pastWeekStats: [],
    activeUsers: {},
    latestBusinesses: [],
    commissionStatus: [],
    influencerCampaignStats: [],
    openInvoices: []
  }),
  methods: {
    ...mapActions("core", [
      "getStats",
      "getBusinesses",
      "getComissionStatus",
      "getInvoices",
      "activeUserQuery"
    ]),
    getCurrentDayStats() {
      const date = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      const dateFull = this.$moment()
        .utc()
        .format("YYYY-MM-DD hh:mm:ss");
      const params = {
        start_date: date,
        end_date: date,
        starting_at: dateFull,
        ending_at: dateFull,
        limit: 10
      };
      this.getStats(params).then(
        response => {
          this.currentDayStats = response[0];
        },
        error => {
          console.log(error);
        }
      );
    },
    getPastWeekStats() {
      const start = this.$moment()
        .subtract(7, "days")
        .utc()
        .format("YYYY-MM-DD");
      const end = this.$moment()
        .subtract(1, "days")
        .utc()
        .format("YYYY-MM-DD");
      const startFull = this.$moment()
        .subtract(7, "days")
        .utc()
        .format("YYYY-MM-DD hh:mm:ss");
      const endFull = this.$moment()
        .subtract(1, "days")
        .utc()
        .format("YYYY-MM-DD hh:mm:ss");
      const params = {
        start_date: start,
        end_date: end,
        starting_at: startFull,
        ending_at: endFull,
        group: ["date"],
        limit: 10
      };
      this.getStats(params).then(
        response => {
          this.pastWeekStats = response;
        },
        error => {
          console.log(error);
        }
      );
    },
    getActiveUserStats() {
      this.activeUserQuery().then(
        response => {
          this.activeUsers = response;
        },
        error => {
          console.log(error);
        }
      );
    },
    getLatestBusinesses() {
      const params = {
        limit: 5,
        page: 1
      };
      this.getBusinesses(params).then(
        response => {
          this.latestBusinesses = response.response;
        },
        error => {
          console.log(error);
        }
      );
    },
    getCommissionStatus() {
      this.getComissionStatus(null).then(
        response => {
          this.commissionStatus = response.data[0];
        },
        error => {
          console.log(error);
        }
      );
    },
    getInfluencerCampaignStats() {
      const date = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      const dateStart = this.$moment()
        .utc()
        .format("YYYY-MM-DD 00:00:00");
        const dateEnd = this.$moment()
        .utc()
        .format("YYYY-MM-DD 23:59:59");
      const params = {
        start_date: date,
        end_date: date,
        starting_at: dateStart,
        ending_at: dateEnd,
        group: ["campaign", "influencer"],
        limit: 100
      };
      this.getStats(params).then(
        response => {
          this.influencerCampaignStats = response;
        },
        error => {
          console.log(error);
        }
      );
    },
    getOpenInvoices() {
      const params = {
        limit: 100,
        unpaid: true
      };
      this.getInvoices(params).then(
        response => {
          this.openInvoices = response;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getCurrentDayStats();
    this.getPastWeekStats();
    this.getActiveUserStats();
    this.getLatestBusinesses();
    this.getCommissionStatus();
    this.getInfluencerCampaignStats();
    this.getOpenInvoices();
  }
};
</script>
